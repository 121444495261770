import React from 'react';
import Item from '../item';

const Tertiary = (props) => {
  const {
    columns,
    sectionRef,
    secondarySectionOpen,
    featuredItem
  } = props;

  return (
    <ul ref={sectionRef} className="SmallMenu-tertiary">
      {columns.map((column, i) => (
        <React.Fragment key={i}>
          {column.map((item) => (
            <Item item={item} sectionOpen={secondarySectionOpen} />
          ))}
        </React.Fragment>
      ))}

      {featuredItem && <Item item={featuredItem} sectionOpen={secondarySectionOpen} />}
    </ul>
  );
};

export default Tertiary;
