import React from 'react';
import cn from 'classnames';

const Item = ({ item, sectionOpen }) => {
  const currentPageUrl = $('#menu-content').data('currentPage');

  const linkClickHandler = (url, type = 'link') => {
    window.dataLayer.push({
      event: 'menu_link_click',
      type: type,
      link_url: url
    });
  };

  const renderFeaturedItem = () => {
    const {
      description,
      image_src,
      linkText,
      url
    } = item.link;

    return (
      <li
        key={url}
        className={cn(
          'SmallMenu-tertiary-item',
          'SmallMenu-tertiary-item--featured'
        )}
      >
        <a href={url} onClick={() => linkClickHandler(url, 'featured')}>
          <img src={image_src} alt="" />
          <h4 className="mb0 pb0 mt2">{linkText}</h4>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </a>
      </li>
    );
  };

  const renderLinkItem = () => {
    const { linkText, linkTitle, url } = item;
    return (
      <li
        key={url}
        className={cn(
          'SmallMenu-tertiary-item',
          'SmallMenu-tertiary-item--link'
        )}
      >
        {currentPageUrl === url && <div className="SmallMenu-tertiary-current" />}
        <a
          href={url}
          onClick={() => linkClickHandler(url)}
          tabIndex={sectionOpen ? 0 : -1}
        >
          {linkText || linkTitle}
        </a>
      </li>
    );
  };

  const renderHeadingItem = () => {
    const { linkText, url } = item.link;

    if (url) {
      return (
        <li
          key={url}
          className={cn(
            'SmallMenu-tertiary-item',
            'SmallMenu-tertiary-item--heading',
            'SmallMenu-tertiary-item--link'
          )}
        >
          {currentPageUrl === url && <div className="SmallMenu-tertiary-current" />}
          <a
            href={url}
            onClick={() => linkClickHandler(url)}
            tabIndex={sectionOpen ? 0 : -1}
          >
            {linkText}
          </a>
        </li>
      );
    }

    return (
      <li
        key={linkText}
        className={cn(
          'SmallMenu-tertiary-item',
          'SmallMenu-tertiary-item--heading'
        )}
      >
        <div>
          {linkText}
        </div>
      </li>
    );
  };

  const { kind } = item;
  let itemElement;
  switch (kind) {
    case 'heading':
      itemElement = renderHeadingItem();
      break;
    case 'link':
    case 'external-link':
      itemElement = renderLinkItem();
      break;
    case 'featuredItem':
      itemElement = renderFeaturedItem();
      break;
    default:
      itemElement = null;
  }

  return itemElement;
};

export default Item;
