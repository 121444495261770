import React from 'react';

const Chevron = ({ className }) => (
  <svg className={className} width="10px" height="6px" viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2018-arrow-nav" fill="#292929" fillRule="nonzero">
        <polygon id="Page-1-Copy-8" transform="translate(5.000000, 3.000000) scale(-1, 1) rotate(90.000000) translate(-5.000000, -3.000000) " points="3.08432 -2 2 -0.897071 5.83136 3.000043 2 6.897158 3.08432 8 6.91568 4.102972 8 3.00013 8 2.999957 6.91568 1.897114"></polygon>
      </g>
    </g>
  </svg>
);

export default Chevron;
