import React, { useState, useRef } from 'react';
import cn from 'classnames';
import Tertiary from '../tertiary';
import { Chevron } from '@react-shared/components/icons';

const Secondary = (props) => {
  const { section, primarySectionOpen } = props;
  const { sectionName, columns, featuredItem } = section;

  const [open, setOpen] = useState(false);
  const sectionRef = useRef(null);

  const toggleSection = () => {
    const $element = $(sectionRef.current);
    $element.slideToggle();
  };

  const toggleOpen = () => {
    const newOpen = !open;
    setOpen(newOpen);
    toggleSection(sectionRef);

    if (newOpen) {
      window.dataLayer.push({
        event: 'menu_section_click',
        label: sectionName
      });
    }
  };

  return (
    <li key={sectionName}>
      <button
        type="button"
        className={cn('SmallMenu-secondary-item', { open })}
        tabIndex={primarySectionOpen ? 0 : -1}
        onClick={toggleOpen}
      >
        {sectionName}
        <Chevron className="SmallMenu-chevron SmallMenu-chevron--secondary" />
      </button>
      <Tertiary
        columns={columns}
        sectionRef={sectionRef}
        secondarySectionOpen={open}
        featuredItem={featuredItem}
      />
    </li>
  );
};

export default Secondary;
