import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ClickOutside } from '@react-shared/components';
import { useKeydownListener, useClickOutsideListener } from '@react-shared/hooks';
import Primary from './primary';

const SmallMenu = (props) => {
  const { menuContent: primarySections } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const menuRef = useRef(null);
  const $menuButton = $('.js-hamburger');
  const $searchButton = $('.js-search-toggler');
  const $searchElement = $('.js-search-dropdown');
  const $searchInput = $('.js-search-input');

  const toggleMenu = () => {
    const newMenuOpen = !menuOpen;
    setMenuOpen(newMenuOpen);
    $menuButton.toggleClass('dn');
    $(menuRef.current).slideToggle(200);

    const menuAction = newMenuOpen ? 'open' : 'close';
    window.dataLayer.push({
      event: `menu_${menuAction}`
    });
  };

  const toggleSearch = () => {
    setSearchOpen((prevOpen) => !prevOpen);
    $searchButton.toggleClass('dn');
    $searchInput.val('');
    $searchElement.slideToggle(200);
    $searchInput.focus();
  };

  useEffect(() => {
    // Set up listeners
    $menuButton.click(toggleMenu);
    $searchButton.click(toggleSearch);
  }, []);

  useKeydownListener({ keyName: 'Escape', onKeydown: toggleMenu, active: menuOpen });
  useKeydownListener({ keyName: 'Escape', onKeydown: toggleSearch, active: searchOpen });
  useClickOutsideListener({ element: $searchElement, onOutsideClick: toggleSearch, active: searchOpen });

  return (
    <ClickOutside onOutsideClick={toggleMenu} active={menuOpen}>
      <nav className={cn('SmallMenu')} ref={menuRef}>
        <ul className="SmallMenu-primary">
          {primarySections.map((primarySection) => (
            <Primary section={primarySection} key={primarySection.name} />
          ))}
        </ul>
      </nav>
    </ClickOutside>
  );
};

SmallMenu.propTypes = {
  menuContent: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default SmallMenu;
