import React from 'react';
import Item from '../item';

const Tertiary = (props) => {
  const { columns, featuredItem } = props;

  return (
    <div className="LargeMenu-tertiary">
      <div className="LargeMenu-tertiary-padding" />
      {columns.map((column, i) => (
        <ul className="LargeMenu-tertiary-column" key={i}>
          {column.map((item) => (
            <Item item={item} />
          ))}
        </ul>
      ))}
      {featuredItem?.kind && (
        <ul className="LargeMenu-tertiary-column">
          <Item item={featuredItem} />
        </ul>
      )}
      <div className="LargeMenu-bottom-border" />
    </div>
  );
};

export default Tertiary;
