import React from 'react';
import ReactDOM from 'react-dom';
import { SmallMenu, LargeMenu } from './components';

const renderMenus = () => {
  const menuElement = document.getElementById('js-menu-desktop');
  const mobileMenuElement = document.getElementById('js-menu-mobile');
  const data = $(menuElement).data('menu-data');

  if (menuElement) {
    ReactDOM.render(
      <LargeMenu menuContent={data} />,
      menuElement
    );
  }

  if (mobileMenuElement) {
    ReactDOM.render(
      <SmallMenu menuContent={data} />,
      mobileMenuElement
    );
  }
};

$('html').on('consumer.dependencies.loaded', () => {
  renderMenus();
});
