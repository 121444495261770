import React, { useEffect } from 'react';

const useClickOutsideListener = ({ element, onOutsideClick, active }) => {
  const handleClickOutside = (event) => {
    const target = $(event.target);
    if (!target.closest(element).length) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    // Attach the click event listener to the document
    if (active) {
      document.addEventListener('click', handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [active]);
};

export default useClickOutsideListener;
