import React, { useRef } from 'react';
import { useClickOutsideListener } from '@react-shared/hooks';

const ClickOutside = ({ children, onOutsideClick, active }) => {
  const componentRef = useRef(null);

  useClickOutsideListener({ element: componentRef.current, onOutsideClick, active });

  return <div ref={componentRef}>{children}</div>;
};

export default ClickOutside;
