import React, { useRef } from 'react';
import cn from 'classnames';
import Secondary from '../secondary';

const Primary = (props) => {
  const {
    section,
    activePrimarySection,
    activeSecondarySection,
    togglePrimarySection,
    toggleSecondarySection
  } = props;

  const {
    name,
    menu_content: secondarySections,
    id
  } = section;

  const open = activePrimarySection === id;
  const sectionRef = useRef(null);

  const toggleOpen = () => {
    togglePrimarySection(id, sectionRef);
  };

  return (
    <li key={name}>
      <button
        type="button"
        className={cn('LargeMenu-primary-item h5', { open })}
        onClick={toggleOpen}
      >
        {name}
      </button>
      <ul ref={sectionRef} className={cn('LargeMenu-secondary')}>
        {secondarySections.map((secondarySection) => (
          <Secondary
            section={secondarySection}
            primarySectionOpen={open}
            activeSecondarySection={activeSecondarySection}
            toggleSecondarySection={toggleSecondarySection}
            secondaryListRef={sectionRef}
            key={secondarySection.sectionName}
          />
        ))}
      </ul>
    </li>
  );
};

export default Primary;
