import React, { useState, useRef } from 'react';
import cn from 'classnames';
import Secondary from '../secondary';
import { Chevron } from '@react-shared/components/icons';

const Primary = ({ section }) => {
  const { name, menu_content: secondarySections } = section;
  const [open, setOpen] = useState(false);
  const sectionRef = useRef(null);

  const toggleSection = () => {
    const $element = $(sectionRef.current);
    $element.slideToggle();
  };

  const toggleOpen = () => {
    const newOpen = !open;
    setOpen(newOpen);
    toggleSection();

    if (newOpen) {
      window.dataLayer.push({
        event: 'menu_section_click',
        label: name
      });
    }
  };

  return (
    <li key={name}>
      <button
        type="button"
        className={cn('SmallMenu-primary-item', { open })}
        onClick={toggleOpen}
      >
        {name}
        <Chevron className="SmallMenu-chevron" />
      </button>
      <ul ref={sectionRef} className={cn('SmallMenu-secondary')}>
        {secondarySections.map((secondarySection) => (
          <Secondary
            section={secondarySection}
            primarySectionOpen={open}
            key={secondarySection.sectionName}
          />
        ))}
      </ul>
    </li>
  );
};

export default Primary;
