import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Tertiary from '../tertiary';

const Secondary = (props) => {
  const {
    section,
    primarySectionOpen,
    activeSecondarySection,
    toggleSecondarySection,
  } = props;
  const {
    sectionName,
    columns,
    featuredItem,
    id
  } = section;

  const open = activeSecondarySection === id;

  const toggleOpen = () => {
    if (!open) {
      window.dataLayer.push({
        event: 'menu_section_click',
        label: sectionName
      });
    }

    toggleSecondarySection(id);
  };

  return (
    <li key={sectionName}>
      <button
        type="button"
        className={cn('LargeMenu-secondary-item fw6', { open })}
        tabIndex={primarySectionOpen ? 0 : -1}
        onClick={toggleOpen}
      >
        {sectionName}
      </button>
      {open && (
        <Tertiary
          columns={columns}
          featuredItem={featuredItem}
        />
      )}
    </li>
  );
};

export default Secondary;
