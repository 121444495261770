import React from 'react';
import cn from 'classnames';
import { Chevron } from '@react-shared/components/icons';

const Item = ({ item }) => {
  const currentPageUrl = $('#menu-content').data('currentPage');

  const linkClickHandler = (url, type = 'link') => {
    window.dataLayer.push({
      event: 'menu_link_click',
      type: type,
      link_url: url
    });
  };

  const renderFeaturedItem = () => {
    const {
      description,
      image_src,
      linkText,
      url
    } = item.link;

    return (
      <li
        key={url}
        className={cn(
          'LargeMenu-tertiary-item',
          'LargeMenu-tertiary-item--featured'
        )}
      >
        <a href={url} onClick={() => linkClickHandler(url, 'featured')}>
          <img src={image_src} alt="" />
          <h5 className="mb0 pb0 mt2 LargeMenu-tertiary-item--heading">
            {linkText}
            <Chevron className="LargeMenu-chevron" />
          </h5>
          <div
            className="LargeMenu-tertiary-item-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </a>
      </li>
    );
  };

  const renderLinkItem = () => {
    const { linkText, linkTitle, url } = item;
    return (
      <li
        key={url}
        className={cn(
          'LargeMenu-tertiary-item',
          'LargeMenu-tertiary-item--link'
        )}
      >
        {currentPageUrl === url && <div className="LargeMenu-tertiary-current" />}
        <a href={url} onClick={() => linkClickHandler(url)} className="a a--decoration-reverse a--black">
          {linkText || linkTitle}
        </a>
      </li>
    );
  };

  const renderHeadingItem = () => {
    const { linkText, url } = item.link;

    if (url) {
      return (
        <li
          key={url}
          className={cn(
            'LargeMenu-tertiary-item',
            'LargeMenu-tertiary-item--heading',
            'LargeMenu-tertiary-item--link'
          )}
        >
          {currentPageUrl === url && <div className="LargeMenu-tertiary-current" />}
          <a href={url} onClick={() => linkClickHandler(url)} className="a a--decoration-reverse a--black">
            {linkText}
            <Chevron className="LargeMenu-chevron" />
          </a>
        </li>
      );
    }

    return (
      <li
        key={linkText}
        className={cn(
          'LargeMenu-tertiary-item',
          'LargeMenu-tertiary-item--heading'
        )}
      >
        <div>
          {linkText}
        </div>
      </li>
    );
  };

  const { kind } = item;
  let itemElement;
  switch (kind) {
    case 'heading':
      itemElement = renderHeadingItem();
      break;
    case 'link':
    case 'external-link':
      itemElement = renderLinkItem();
      break;
    case 'featuredItem':
      itemElement = renderFeaturedItem();
      break;
    default:
      itemElement = null;
  }

  return itemElement;
};

export default Item;
