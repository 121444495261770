import React, { useEffect } from 'react';

const useKeydownListener = ({ keyName, onKeydown, active }) => {
  const handleKeyDown = (event) => {
    if (event.key === keyName) {
      onKeydown();
    }
  };

  useEffect(() => {
    const listener = (event) => handleKeyDown(event);

    // Attach the keydown event listener to the document
    if (active) {
      document.addEventListener('keydown', listener);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [active]);
};

export default useKeydownListener;
