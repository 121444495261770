import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Primary from './primary';
import { ClickOutside } from '@react-shared/components';

const LargeMenu = (props) => {
  const { menuContent: primarySections } = props;
  const [activePrimarySection, setActivePrimarySection] = useState(null);
  const [activeSecondarySection, setActiveSecondarySection] = useState(null);

  const togglePrimarySection = (sectionId, sectionRef) => {
    // Close all sections
    const closingAllSections = activePrimarySection && activePrimarySection === sectionId;
    if (closingAllSections) {
      $('.LargeMenu-secondary').slideUp();
    } else {
      $('.LargeMenu-secondary').hide();
    }

    setActivePrimarySection((prevState) => {
      const openPrimarySection = prevState !== sectionId;
      if (openPrimarySection) {
        // Open section
        if (prevState) {
          $(sectionRef.current).show();
        } else {
          $(sectionRef.current).slideDown();
        }

        const primarySection = primarySections.find(
          (section) => section.id === sectionId
        );

        const firstSecondarySection = primarySection?.menu_content[0].id;
        if (firstSecondarySection) {
          setActiveSecondarySection(firstSecondarySection);
        }

        window.dataLayer.push({
          event: 'menu_section_click',
          label: primarySection?.name
        });

        return sectionId;
      }

      return null;
    });
  };

  const toggleSecondarySection = (sectionId) => {
    setActiveSecondarySection(sectionId);
  };

  const closeMenu = () => {
    // Close all sections
    $('.LargeMenu-secondary').slideUp();
    setActivePrimarySection(null);
    setActiveSecondarySection(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeMenu();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ClickOutside onOutsideClick={closeMenu} active={activePrimarySection !== null}>
      <nav className="LargeMenu">
        <ul className="LargeMenu-primary">
          {primarySections.map((primarySection) => (
            <Primary
              section={primarySection}
              activePrimarySection={activePrimarySection}
              activeSecondarySection={activeSecondarySection}
              togglePrimarySection={togglePrimarySection}
              toggleSecondarySection={toggleSecondarySection}
              key={primarySection.name}
            />
          ))}
        </ul>
      </nav>
    </ClickOutside>
  );
};

LargeMenu.propTypes = {
  menuContent: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default LargeMenu;
